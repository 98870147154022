import React from 'react'
import i18next from 'i18next'
import { useNavigate } from 'react-router-dom'
import Button from 'components/Button'
import AccountLayout from './Account/AccountLayout'

const PrivacySettings = () => {
  const navigate = useNavigate()

  return (
    <AccountLayout title={i18next.t('employee.account.privacy.title')} onBackPress={() => navigate(-1)}>
      <Button name="reset_cookies" className="cky-banner-element" type="button" onClick={() => {}}>
        {i18next.t('employee.account.privacy.cookies')}
      </Button>
    </AccountLayout>
  )
}

export default PrivacySettings
