import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import i18next from 'i18next'
import httpClient from 'utils/http-client'
import { setEmployeeId, trackEvent } from 'utils/amplitude'
import IconButton from 'components/IconButton'
import { OnChangeFormFunction, SignupForm } from 'employee/types/signup'

import Password from './Signup/Password'
import Disclaimer from './Signup/Disclaimer'

const Signup = () => {
  const [search] = useSearchParams()
  const navigate = useNavigate()

  const [step, setStep] = React.useState(0)
  const [form, setForm] = React.useState<SignupForm>({
    token: search.get('token') ?? 'invalid token',
    password: '',
    first_name: '',
    last_name: '',
  })
  const [errors, setErrors] = React.useState<Record<string, string>>({})

  const employeeId = search.get('employee_id')

  useEffect(() => {
    if (!employeeId) return

    setEmployeeId(employeeId)
    trackEvent('signup-start-view')
  }, [employeeId])

  const onChange: OnChangeFormFunction = (key, value) => setForm((currentForm) => ({ ...currentForm, [key]: value }))

  const onSubmit = () => {
    httpClient('/signup/emails', { method: 'post', body: form })
      .then(() => {
        navigate('/agencies')
      })
      .catch((error: Record<string, string>) => {
        if (error.message === 'Invalid token') {
          setErrors({ token: i18next.t('employee.signup.invalid_token') })
        }
      })
  }

  return (
    <div className="min-h-dvh p-6 w-full bg-backgroundPrimary flex flex-col z-0">
      {errors.token ? (
        <div>{i18next.t('employee.signup.expired_validation')}</div>
      ) : (
        <>
          {step > 0 ? (
            <IconButton
              onClick={() => setStep((currentStep) => currentStep - 1)}
              label={i18next.t('common.back')}
              name="arrow_back"
              className="mb-6 self-start absolute z-10"
              iconClassName="w-6 h-6"
            />
          ) : null}
          {step === 0 ? (
            <Disclaimer
              agencyName={search.get('agency_name') ?? ''}
              onNext={() => setStep((currentStep) => currentStep + 1)}
            />
          ) : null}
          {step === 1 ? <Password value={form.password} onChange={onChange} onNext={onSubmit} /> : null}
        </>
      )}
    </div>
  )
}

export default Signup
